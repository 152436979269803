.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  font-family: 'Outfit', sans-serif;
  background-color: #000E29;
  color: #FFFFFF;
  flex-wrap: wrap;
}

.contact-form {
  width: 50%;
  background-color: #000E29;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  font-size: 50px;
  font-weight: 900;
  margin-top: 30px;
  margin-left: 150px;

}

.contact-form p {
  font-size: 17px;
  margin-left: 20px;
  text-align: center;
  margin-bottom: 40px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #FF6600;
  border-radius: 5px;
  background-color: #262626;
  color: #FFFFFF;
}

.contact-form textarea {
  height: 150px;
  resize: none;
}

.contact-info {
  width: 50%;
  background-color: #FFFFFF;
  padding: 75px;
  border-radius: 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #000E29;
}

.contact-info h2 {
  font-size: 40px;
  color: #000E29;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 16px;
  margin-bottom: 30px;
}



.contact-info div img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.view-more-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FF6600;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  margin-top: 10px;
  margin-left: 235px;
}


.contact-info div p {
  margin: 0;
}

.contact-info div a {
  color: #FFFFFF;
  text-decoration: none;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-form, .contact-info {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
  }

  .contact-form {
    margin-bottom: 30px;
  }

  .contact-form h2 {
    font-size: 30px;
    margin-left: 0;
  }

  .contact-form p {
    margin-left: 0;
  }

  .contact-info {
    padding: 20px;
  }

  .view-more-button {
    margin-left: 0;
    margin-top: 20px;
  }
}
