/* General Styles */
.hero-container {
  position: relative;
  width: 100%;
  height: 556px; 
  background: linear-gradient(0deg, rgba(0, 14, 41, 0.82), rgba(0, 14, 41, 0.82)), url('../../assets/heroservice.png');
  background-size: cover;
  background-position: center;
}

.hero-content {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 230px;
}

.hero-title {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.75rem;
  line-height: 1.2;
  color: #FFFFFF;
}



.group-companies {
  text-align: center;
  margin-bottom: 50px;
}

.group-companies h1 {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.75rem;
  line-height: 1.2;
  color: #000E29;
}

.services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.call-to-action-section {
  margin-top: 50px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .hero-container {
    height: 350px; /* Adjusted height for mobile */
  }

  .hero-title {
    font-size: 2.5rem; /* Adjusted font size for mobile */
    top: 120px; /* Adjusted top position for mobile */
  }

  .services-container {
    padding: 50px 10px; /* Reduced padding for mobile */
  }

  .group-companies h1 {
    font-size: 2.5rem; /* Adjusted font size for mobile */
  }

  .services-content {
    flex-direction: column; /* Adjusted layout for mobile */
    align-items: center; /* Centered items for mobile */
    gap: 30px; /* Increased gap for mobile */
  }

  .call-to-action-section {
    margin-top: 30px; /* Reduced margin for mobile */
  }
}
